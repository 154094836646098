import { CardOptions } from '@cards-utils/card-options.enum';

/**
 * Reset card
 */
export class ResetCard {
  static readonly type = '[Card] Reset';

  constructor() {}
}

/**
 * Reset card to default
 */
export class ResetCardToDefault {
  static readonly type = '[Card] Reset To Default';

  constructor() {}
}

/**
 * Set active card
 */
export class SetActiveCard {
  static readonly type = '[Card] Set Active';

  constructor(
    public id: string,
    public route: CardOptions,
    public object: Record<string, any> | null,
    public objects?: Record<string, any>[]
  ) {}
}

/**
 * Set disable navigation
 */
export class SetDisableNavigation {
  static readonly type = '[Card] Set Disable Navigation';

  constructor(public payload: boolean) {}
}

/**
 * Add active card
 */
export class AddActiveCard {
  static readonly type = '[Card] Add Active';

  constructor(public id: string, public route: CardOptions, public object: Record<string, any> | null) {}
}

/**
 * Navigate to card
 */
export class NavigateToCard {
  static readonly type = '[Card] Navigate To';

  constructor(public payload: string) {}
}

/**
 * Update card object
 */
export class UpdateCardObject {
  static readonly type = '[Card] Update Card Object';

  constructor(public id: string, public object: Record<string, any> | null) {}
}

/**
 * Update card objects
 */
export class UpdateCardObjects {
  static readonly type = '[Card] Update Card Objects';

  constructor(public id: string, public objects: Record<string, any>[]) {}
}

/**
 * Change last card
 */
export class ChangeLastCard {
  static readonly type = '[Card] Change Last Card';

  constructor(public id: string, public route: CardOptions, public object: Record<string, any> | null) {}
}

/**
 * Navigate to previous card
 */
export class NavigateToPreviousCard {
  static readonly type = '[Card] Navigate To PreviousCard';

  constructor() {}
}
